.container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height:100%
    /* min-height: 800px; */
  }
.admin-actions-box {
    width: 25%;
    min-width: 380px;
    margin-top: 15px;
    border-radius: 10px;
}

.create-new-user-box {
    margin-top: 10px;
    background-color: white;
    border-radius: 10px;
    min-height: 75px;
    display: flex;
    flex: 1 100%;
    align-items: center;
}

.add-user-button {
    margin-left: auto;
    margin-right: 15px;
}

.add-user-button:hover {
    background-color: '#fff'
}

.create-user-text-box {
    margin-left: 15px;
}

.users-box {
    margin-top: 25px;
    margin-bottom: 60px;
    height: 100%;
    width: 55%;
    min-width: 700px;
}

.title-and-search-box {
    display: flex;
    justify-content: space-between;
}

.searchBar {
   height: 1px;
   margin-bottom: 35px;
}

.users-data-grid {
    margin-top: 15px;
    border-radius: 10px !important;
    /* border-top: 0 !important; */
}

.users-data-grid-headers {
    color: #b8b8b8
}

.dialog-actions {
    justify-content: end !important;
    flex-wrap: wrap !important;
    margin-right: 15px;
    margin-bottom: 10px;
}

.item-type-label {
    margin-top: 7px !important;
}

.cancel-button {
    padding: 7px 20px 7px 20px;
    border-radius: 10px;
}

.add-Button {
   background-color: #1976d2;
   text-align: center;
   color: white;
   padding: 7px 20px 7px 20px;
   border-radius: 10px;
   &:hover {
    background-color: #1976d2;
}
}

.delete-button {
    color: white;
    background-color: #BE1313;
    padding: 7px 20px 7px 20px;
    border-radius: 10px;
    &:hover {
        background-color: #BE1313;
    }
}

.evenRow {
    background: #f4f4f4;
}

.icon-button {
    width: 55px;
    height: 50px;
    border-radius: 9px;
    background-color: #f2f8fb;
    margin-left: 10px;
    cursor: default;
  }

  .user-details-title {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    font-size: 22px !important;
}

.assign-role {
    padding: 0px !important;
    margin: 20px 0px 0px 0px  !important;
    font-size: 22px !important;
}

.custom-avatar {
    width: 30px;
    height: 30px;
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
    color: #396f89;
    background-color: #dce8ed;
}
  .custom-dialog .MuiDialog-paper {
    width: 100%;
    max-width: 500px;
    border-radius: 15px;
    margin: 0px auto;
    padding: 10px;
}