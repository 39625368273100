.rct-collapse, .rct-checkbox, .rct-node-icon {
    padding: 0px !important;
}

.rct-title {
    padding: 0.5em 0.2em 0.5em 0.2em !important;
    display: flex;
    box-sizing: border-box;
    overflow: hidden;
}
.boldTextItem {
    font-weight: bold;
    padding-right: 3px;
}
.rct-node-leaf {
    padding-left: 0px;
}

.rct-collapse *, .rct-checkbox *, .rct-node-icon * {
    width: 24px !important;
}

.treeNode {
    border-radius: 4px;
    width: 24px;
    height: 24px;
    box-shadow: rgba(16, 22, 26, 0.2) 0px 0px 0px 1px inset, rgba(16, 22, 26, 0.1) 0px -1px 0px inset;
    background-color: white;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
}

.checked-color {
    color: rgb(19, 124, 189);
    border-radius: 4px;
}

.unchecked-color {
    background-color: white;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
    color: rgba(0,0,0,0.2);
    border-radius: 4px;
}

.controls-container {
    display: flex;
    justify-content: stretch;
    align-items: flex-start;
    margin-bottom: 9px;
    margin-Top : 7px
}

.tree-container {
    display: flex;
    flex-direction: column;
    min-height: 800px;
}
.search-bar, .tree-container {
    width: 100%;
}

/* there is a another way to hide the checkbox needs to be inspected */
.hide-checkboxes .rct-checkbox { 
    display: none; 
}

.search-bar-input:hover{
    background-color: #F8F8F8;      
  }
.crud-bar {
    display: inline-flex;
    justify-content: space-between;
}
.horizontal-ruler {
    margin: 0.75em 0;
}

.search-bar .MuiOutlinedInput-adornedEnd {
    padding-right: 0px;
}

.search-bar:hover .MuiOutlinedInput-adornedEnd {
    background-color: #F8F8F8;
}

.search-bar .MuiInputBase-adornedEnd {
    border-radius: 12px;
}

.search-bar .MuiIconButton-sizeMedium {
    padding-right: 0px;
}

.react-checkbox-tree label:hover {
    background: none !important;
}

.react-checkbox-tree {
    display: block !important;
}

.badge-container {
    margin: 0 10px 0 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8125rem;
}

.count-icon {
    background: #e9e9e9;
}

.error-icon {
    background: rgb(190, 19, 19);
    color: rgb(2555,255,255);
    min-width: 20px;
}

.moreIcon button {
    padding: 0 0 0 0px;
    border: 0.1px grey solid;
    background-color: white;
    border-radius: 0%;
}

.moreIcon .MuiIconButton-sizeMedium:hover {
    background: none;
}

.rct-title .moreIcon {
    display: none;
}

.active {
    display: inline-flex;
}

.inactive {
    display: none;
}

.label-outer-container {
    display: flex;
    width: 100%;
}

.rct-title:hover .label-outer-container{
    width: 95%;
}

.rct-title:hover .moreIcon {
    display: flex;
    width: 5%;
}

.rct-node-clickable {
    width: 100%;
    overflow: auto;
}

.rct-node-clickable:hover, .rct-node-clickable:focus {
    background-color: rgb(0, 0, 0, .04) !important;
}

.active-color {
    background-color: #add8e6;
}

.moreIcon {
    display: inline;
    width: 100%;
    
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .show {display: block;}

  .rct-title:hover {
    height: inherit;
  }

  #checkbox-tree-view {
    /* height: 0vh; */
    /* height: 100%; */
    max-height: 780px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #checkbox-tree-view::-webkit-scrollbar {
    width: 15px;
    border-radius: 10px;
    background-color: #dbdbdb;
  }

  #checkbox-tree-view::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
}

#checkbox-tree-view::-webkit-scrollbar-thumb {
    background-color: #dbdbdb;
    border-radius: 10px;
}
.status-chip {
    color: whiteSmoke;
    font-size: 0.8125rem;
    border-radius: 16px;
    padding: 0 8px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    margin-right: 5px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
.status-chip-new {
    background: rgba(2, 122, 174, 1);
}
.status-chip-updated {
    background :  #5d6d7e;
  }
.status-chip-obsolete {
    background: rgba(0, 0, 0, 0.08);
    color : rgba(0, 0, 0, 0.87) !important;  
}

.label-container {
    margin-right: 10px ;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.root2 {
    padding: 0;
    min-height: 800px;
    height: 100%;
    flex-grow: 1;
}

.paper {
    display: flex;
    flex-direction: column;
    padding: 12px; /* Adjust based on your theme spacing value */
    color: #000000; /* Replace with the actual text color from your theme */
    height: 100%;
    min-height: 400px;
    width: 100%;
    border-radius: 16px;
}

.treeview {
    flex-grow: 1;
    max-height: 100%;
    width: 100%;
    display: flex;
}

.viewBtn {
    background-color: #1976d2;
}

.viewBtn:hover {
    background-color: #1976d2;
}