.permanent-drawer-paper {
    width: 132px !important;
    box-sizing: border-box;
}

.paper-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.paper-list-item-text {
    font-size: 16px !important;
    cursor: pointer;
    text-align: center;
}

.paper-list-logo {
    position: absolute;
    bottom: 1%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.currently-active {
    background: whitesmoke !important;
}

.drawer-toolbar {
    justify-content: center;
    min-height: 52px;
}

.paper-list-item .MuiButtonBase-root.MuiIconButton-root {
    font-size: 50px !important;
}
